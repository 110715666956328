import { API_BASE_URL } from '../../config/api';
import { fetchWithAuth, Auth } from '../../utils/fetch';
import Recommendation from '../../interfaces/Recommendation';
import RecommendationReasons from '../../interfaces/RecommendationReasons';

export interface GetRecommendsPayload {
  occasionId: string;
  budget: number;
}

export interface GetGlobalRecommendationsPayload {
  occasionName: string;
  budget: number;
}

export const getRecommendations = (auth: Auth, data: GetRecommendsPayload) =>
  fetchWithAuth<Recommendation[]>(auth, `${API_BASE_URL}/rec`, { method: 'POST', data });

export const getGlobalRecommendations = (auth: Auth, data: GetGlobalRecommendationsPayload) =>
  fetchWithAuth<Recommendation[]>(auth, `${API_BASE_URL}/rec/global`, { method: 'POST', data });

export const getRecommendationReasons = (auth: Auth, correlationId: string) =>
  fetchWithAuth<RecommendationReasons>(auth, `${API_BASE_URL}/rec/reasons/${correlationId}`, { method: 'GET' });
