import { useState, useEffect } from 'react';

import Recommendation from '../../interfaces/Recommendation';
import RecommendationReasons from '../../interfaces/RecommendationReasons';
import { useAuth } from '../../providers/auth';
import { getRecommendations, GetRecommendsPayload, getRecommendationReasons, getGlobalRecommendations } from './api';

export interface RecommendationsHook {
  data: Recommendation[];
  reasons: RecommendationReasons | null;
  loading: boolean;
  error: any;
  fetch(payload: any, throwError?: boolean): Promise<void>;
}

const useRecommendations = ({
  occasionId,
  budget,
  occasionName,
}: Partial<GetRecommendsPayload & { occasionName?: string }> = {}): RecommendationsHook => {
  const auth = useAuth();

  const [data, setData] = useState<Recommendation[]>([]);
  const [reasons, setReasons] = useState<RecommendationReasons | null>(null);
  const [loading, setLoading] = useState(!!(occasionId && budget) || false);
  const [error, setError] = useState<any>(null);

  const fetchReasons = async (id: string, throwError?: boolean) => {
    setLoading(true);
    try {
      const { data: respData } = await getRecommendationReasons(auth, id);
      setReasons(respData);
      setError(null);
    } catch (err) {
      setError(err);

      if (throwError) {
        throw err;
      }
    } finally {
      setLoading(false);
    }
  };

  const fetch = async (payload: any, append: boolean, throwError?: boolean) => {
    setLoading(true);
    try {
      let response;
      if (payload.occasionId === 'global') {
        response = await getGlobalRecommendations(auth, {
          occasionName: payload.occasionName,
          budget: payload.budget,
        });
      } else {
        response = await getRecommendations(auth, payload);
      }

      const { data: recommends, headers } = response;

      setData(prev => {
        const combined = append ? [...recommends, ...prev] : recommends;
        const unique = Array.from(new Map(combined.map(item => [item.id, item])).values());
        return unique;
      });
      setError(null);

      const correlationId = headers?.['x-correlation-id'];
      if (correlationId) {
        await fetchReasons(correlationId, throwError);
      }
    } catch (err) {
      setError(err);

      if (throwError) {
        throw err;
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (occasionId === 'global' && budget) {
      fetch({ occasionId: 'global', occasionName, budget }, false);
    } else if (occasionId && budget) {
      fetch({ occasionId, budget }, false);
    }
  }, [occasionId, occasionName, budget]);

  return {
    data,
    reasons,
    loading,
    error,
    fetch,
  };
};

export default useRecommendations;
