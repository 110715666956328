import React, { useEffect, useRef, useState } from 'react';
import { Typography } from '@mui/material';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import CardGiftcardIcon from '@mui/icons-material/CardGiftcard';
import { useNavigate } from 'react-router-dom';

import dayjs from 'dayjs';
import Input from '../../../components/ui-kit/Input';
import Button from '../../../components/ui-kit/Button';
import isBudgetValid from '../../../utils';
import Notification from '../../../interfaces/Notification';
import Budget from '../../../config/budget';

interface EventCardProps {
  event: Notification;
}

const getDaysUntilEvent = (event: any): string => {
  const today = dayjs();
  let eventDate = dayjs(event.date);

  if (eventDate.isBefore(today, 'day')) {
    eventDate = eventDate.add(1, 'year');
  }

  const diff = eventDate.diff(today, 'days');

  if (diff === 0) {
    return 'today';
  }

  return `${diff} day${diff > 1 ? 's' : ''}`;
};

const EventCard: React.FC<EventCardProps> = ({ event }) => {
  const [budgetValue, setBudgetValue] = useState<string>('');
  const [isEditingBudget, setIsEditingBudget] = useState<boolean>(false);
  const budgetInpRef = useRef<HTMLInputElement>(null);
  const navigate = useNavigate();

  useEffect(() => {
    if (isEditingBudget) {
      budgetInpRef.current?.focus();
    }
  }, [isEditingBudget]);

  const handleBudgetChange = (value: string) => {
    const sanitizedValue = value.replace(/^0+/, '');
    setBudgetValue(sanitizedValue);
  };

  const handleSaveBudget = () => {
    if (isBudgetValid(budgetValue)) {
      setIsEditingBudget(false);

      if (event.isGlobal) {
        navigate(
          `/connections/global?isGlobal=true&occasionName=${encodeURIComponent(event.occasion)}&budget=${budgetValue}`,
        );
      } else {
        navigate(`/connections/${event.contactId}?occasionId=${event.id}&budget=${budgetValue}`);
      }
    }
  };

  return (
    <div className="flex flex-col border-b-2 border-[#f9f8fa85] text-[#F9F8FA] max-w-[550px] min-h-[64px] flex-wrap sm:flex-nowrap justify-between items-start">
      <div className="flex items-center gap-2 md:gap-4 xl:gap-6 w-full">
        <div>
          <CardGiftcardIcon sx={{ color: 'white', fontSize: 24 }} />
        </div>
        <div>
          <Typography
            className="text-lg font-bold"
            sx={{
              wordBreak: 'break-word',
              overflowWrap: 'break-word',
              whiteSpace: 'normal',
              lineHeight: 1.2,
              textAlign: 'left',
            }}
          >
            {getDaysUntilEvent(event)} until {event.isGlobal ? event.occasion : `${event.name}’s ${event.occasion}`}
          </Typography>
        </div>
        <div className="ml-auto">
          {isEditingBudget ? (
            <form
              onSubmit={e => {
                e.preventDefault();
                handleSaveBudget();
              }}
            >
              <div className="flex flex-col items-end gap-1">
                <div className="flex gap-1">
                  <Input
                    name="budget"
                    type="number"
                    autoComplete="off"
                    size="small"
                    helperText={`Min ${Budget.MIN}, Max ${Budget.MAX}`}
                    FormHelperTextProps={{ style: { margin: '0 10px', textWrap: 'nowrap' } }}
                    ref={budgetInpRef}
                    value={budgetValue}
                    sx={{
                      width: 128,
                      '@media (max-width: 425px)': {
                        width: 84,
                      },
                    }}
                    onChange={({ target }) => handleBudgetChange(target.value)}
                  />
                  <Button
                    type="submit"
                    className="!w-[40px] !min-w-[40px] !h-[40px] !p-1"
                    disabled={!isBudgetValid(budgetValue)}
                  >
                    <CheckRoundedIcon />
                  </Button>
                </div>
              </div>
            </form>
          ) : (
            <Button
              onClick={() => {
                setIsEditingBudget(true);
                setBudgetValue('');
              }}
              sx={{ color: '#009F93', backgroundColor: '#FFDC73', textTransform: 'initial' }}
            >
              Budget
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

export default EventCard;
