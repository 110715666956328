import React, { useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { Alert, CircularProgress, Typography } from '@mui/material';

import Budget from '../../config/budget';
import useContacts from '../../data-hooks/contacts/useContacts';
import useRecommendations from '../../data-hooks/recommendations/useRecommendations';
import { ReactComponent as LogoIcon } from '../../assets/present.svg';
import FindUsSection from '../UserAccount/FindUsSection';
import BottomNavBar from '../../components/NavigationBar';
import RecommendationCard from '../../components/RecommendationCard';
import RefreshSuggestions from './RefreshSuggestions';

const Recommendations: React.FC = () => {
  const navigate = useNavigate();
  const { id: connectionId } = useParams();
  const [queryParams] = useSearchParams();
  const [refreshing, setRefreshing] = useState(false);

  const queryOccasionId = queryParams.get('occasionId') || undefined;
  const queryOccasionName = queryParams.get('occasionName') || undefined;
  let budget = Budget.MAX;
  if (queryParams.get('budget')) {
    budget = Number.parseFloat(queryParams.get('budget') || '');
    if (budget < Budget.MIN) {
      budget = Budget.MIN;
    }
    if (budget > Budget.MAX) {
      budget = Budget.MAX;
    }
  }

  const { selected: connection, loading: connectionLoading } =
    connectionId !== 'global' ? useContacts({ fetchById: connectionId }) : { selected: null, loading: false };

  const {
    data: recommends,
    reasons,
    loading: recommendsLoading,
    error,
    fetch,
  } = useRecommendations({
    occasionId: connectionId === 'global' ? 'global' : queryOccasionId,
    occasionName: connectionId === 'global' ? queryOccasionName : undefined,
    budget,
  });

  const formatPrice = (price: string): string => {
    let priceSymbol: string;
    if (price.includes('€')) {
      priceSymbol = '€';
    } else {
      priceSymbol = '$';
    }

    return price.split(priceSymbol).reduce((res, item, i) => {
      return item?.length ? `${res} ${i > 0 && res.length ? '/' : ''} ${item.trim()}${priceSymbol}` : res;
    }, '');
  };

  const handleMoreSuggestions = async () => {
    try {
      setRefreshing(true);
      await fetch(
        connectionId === 'global'
          ? { occasionId: 'global', occasionName: queryOccasionName, budget }
          : { occasionId: queryOccasionId, budget },
        true,
      );
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error('Failed to fetch more suggestions:', err);
    } finally {
      setRefreshing(false);
    }
  };

  return (
    <div className="flex flex-col min-h-screen relative bg-green-main">
      <div className="p-5 text-center self-center">
        <LogoIcon />
      </div>
      <div
        className="!h-[50px] text-white !bg-[length:100%_100%]"
        style={{ background: 'url("/section-curve.png") no-repeat' }}
      />

      <div className="grow flex bg-white pb-20">
        <div className="container min-h-full flex flex-col">
          <Typography variant="h5" className="text-center text-blue-light">
            Recommendations for
            <br />
            {connectionId !== 'global' && !!connection && <span className="!break-words">{connection?.name}</span>}
            {connectionId === 'global' && queryOccasionName && (
              <span className="!break-words">{queryOccasionName}</span>
            )}
          </Typography>
          {connectionLoading && !connection && (
            <div className="flex justify-center items-center h-[40vh]">
              <CircularProgress size={40} />
            </div>
          )}
          <div className="pb-3">
            <div className="pt-5">
              {!!error && (
                <div>
                  <Alert severity="error">Something went wrong. Try again later, please.</Alert>
                </div>
              )}
              {!recommends?.length && !recommendsLoading && !error && (
                <div>
                  <Alert severity="info">No recommendations. Try again later, please.</Alert>
                </div>
              )}

              {recommends?.length > 0 ? (
                recommends.map(({ id, title, originalTitle, image, link, price }) => (
                  <RecommendationCard
                    key={id}
                    id={id}
                    title={title}
                    originalTitle={originalTitle}
                    image={image}
                    link={link}
                    price={price}
                    reason={reasons?.[id]}
                    loading={recommendsLoading}
                    formatPrice={formatPrice}
                  />
                ))
              ) : (
                <div className="flex justify-center items-center py-10">
                  <CircularProgress size={40} />
                </div>
              )}

              {!refreshing && recommends.length > 0 && (
                <div className="mb-3">
                  <RefreshSuggestions onClick={handleMoreSuggestions} />
                </div>
              )}
            </div>
          </div>
          <FindUsSection color="#009F93" />
          <BottomNavBar
            onMenuClick={() => navigate('/user-account')}
            onConnectionsClick={() => navigate('/')}
            onEventsClick={() => navigate('/events')}
          />
        </div>
      </div>
    </div>
  );
};

export default Recommendations;
